

//import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { AmbientLight, DirectionalLight } from 'three'
import gsap from 'gsap'
// Canvas
const canvas = document.querySelector('canvas.webgl')
// Scene
const scene = new THREE.Scene()
const Group1 = new THREE.Group()
const Group2 = new THREE.Group()
const Group4 = new THREE.Group()
const Group5 = new THREE.Group()
scene.add(Group1,Group2,Group4,Group5)
// //////////////////////////////////////////////////////////////LOGO
const gltfLoader = new GLTFLoader()
let model = null
gltfLoader.load(
'./230801_Logo_V10-2.gltf',
    (gltf) =>
    {
//        const children = [...gltf.scene.children]
//for(const child of children)
//{
//    child.scale.set (0.005,0.005,0.005)
//    child.position.set (0,0,0)
//    child.castShadow = true
//    child.receiveShadow = true
//    Group1.add(child)
//        child.rotation.y = 90
//
//}

  const firstChild = gltf.scene.children[1];
  firstChild.scale.set(0.005, 0.005, 0.005);
  firstChild.position.set(0, 0, 0);
  firstChild.rotation.set(0, 0, 0);
  firstChild.castShadow = true;
  firstChild.receiveShadow = true;
  Group1.add(firstChild);
  //firstChild.rotation.y = 90;

  // Laden des zweiten Kindes
  const secondChild = gltf.scene.children[0];
  secondChild.scale.set(0.005, 0.005, 0.005);
  secondChild.position.set(0, 0, 0);
  secondChild.rotation.set(0, 1, 0);
  secondChild.castShadow = false;
  secondChild.receiveShadow = false;
  Group2.add(secondChild);
  //secondChild.rotation.y = 90;
    }  
    )

/////////////////////////////////////////////////////////////////////Particles
//
//const particlesGeometry = new THREE.BufferGeometry(1, 32, 32)
//const count = 500
//
//const positions = new Float32Array(count * 3)
//
//for(let i = 0; i < count * 3; i++)
//{
//    positions[i] = (Math.random() - 0.5) * 4
//}
//
//particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
//
//
//
//
//////MATERIAL
//const particlesMaterial = new THREE.PointsMaterial({
//    size: 0.005,
//    sizeAttenuation: true,
//   // color: 0xe6c298 
//    color: 0xd7f2ff      
//})
//
//
////Points
//const Particles = new THREE.Points(particlesGeometry,particlesMaterial)
//Group1.add(Particles)

const parameters = {}
parameters.count = 100
parameters.size = 0.02

const generateGalaxy = () =>
{
    /**
     * Geometry
     */
    const geometry4 = new THREE.BufferGeometry()

    const positions = new Float32Array(parameters.count * 3)

    for(let i = 0; i < parameters.count; i++)
    {
        const i3 = i * 3

        positions[i3    ] = (Math.random() - 0.5) * 3
        positions[i3 + 1] = (Math.random() - 0.5) * 3
        positions[i3 + 2] = (Math.random() - 0.5) * 3
    }

    geometry4.setAttribute('position', new THREE.BufferAttribute(positions, 3)
    )


    /**
     * Material
     */
    const material4 = new THREE.PointsMaterial({
        size: parameters.size,
        sizeAttenuation: true,
        depthWrite: false,
        blending: THREE.AdditiveBlending
    })

    const Point = new THREE.Points(geometry4, material4)
    Group1.add(Point)
}


generateGalaxy()
















//////////////////////////////////////////////////////////////////LIGHTS LOGO
//const ambientLight = new THREE.AmbientLight(0xffffff, 0.1)
//scene.add(ambientLight)
//const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8)
//directionalLight.position.set(-2,2,-2)
//directionalLight.rotation.y = Math.PI
//Group2.add(directionalLight)
const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.8)
directionalLight2.position.set(2,-2,-2)
directionalLight2.rotation.y = Math.PI
Group2.add(directionalLight2)
//const directionalLight3 = new THREE.DirectionalLight(0xffffff, 0.3)
//directionalLight3.position.set(5,-5,2)
//directionalLight3.rotation.y = Math.PI
//Group2.add(directionalLight3)
const directionalLight4 = new THREE.DirectionalLight(0xffffff, 1.2)
directionalLight4.position.set(0,-4,-1.5)
directionalLight4.rotation.y = Math.PI
Group4.add(directionalLight4)
const directionalLight5 = new THREE.DirectionalLight(0xffffff, 1.2)
directionalLight5.position.set(0,4,1.5)
directionalLight5.rotation.y = Math.PI
Group5.add(directionalLight5)



/**
 * Sizes
 */


const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
//////////////////////////////////////////////////////////////
let scrollY = window.scrollY

window.addEventListener('scroll', () =>
{
    scrollY = window.scrollY

    //console.log(scrollY)
})



//////////////////////////////////////////////////////RESIZE

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight


    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})



/**
 * Camera
 */
// Base camera
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

const camera = new THREE.PerspectiveCamera( 45, sizes.width / sizes.height, 0.5, 15)
//camera.position.x = 0.7
camera.position.x = 0
camera.position.z = 3
camera.lookAt(0,0,0)
cameraGroup.add(camera)

const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.keys = {}
controls.mouseButtons = {}
controls.touches = {}
controls.update()
/** 
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
   alpha: true,
   antialias: 2
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


/////////////////////////////////////////////SCROLL

/**
 * Scroll
 */
//var scrollLeft = 0; // Anfangswert für scrollLeft
//var scrollContainer = document.getElementById("scrollContainer");
//
//scrollContainer.addEventListener("scroll", function() {
//  scrollLeft = scrollContainer.scrollLeft + 1 ;
//  
//});
//
//// Die Variable scrollLeft kann jetzt außerhalb der Funktion verwendet werden
//console.log("scroll", scrollLeft);











/**
 * Cursor
 */
const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width
    cursor.y = event.clientY / sizes.height

    //console.log(cursor)
})

/**
 * Animate
 */

//const Contact = document.getElementById('ContactLotti');
//Contact.addEventListener('click', () => {
//    const myAnimation = gsap.to(camera.position, {
//        duration: 1, 
//        z: 3.5,
//        x: 0.9,
//        y: 0.2,
//        ease: "power2.inOut"    
//    });
//    const myAnimation2 = gsap.to(Group2.rotation, {
//        duration: 1.5, 
//        x: 0,
//        z: Math.PI,
//        y: Math.PI*2.25,
//        ease: "power2.inOut"    
//    });
//    const myAnimationSphere = gsap.to(Group5.position, {
//        duration: 1, 
//        y: -3.5,
//       // x: 30.5, 
//        ease: "power2.inOut"  
//    });
//  myAnimation.play();
//  myAnimation2.play();
//  myAnimationSphere.play();
// console.log(cursor)
//});


//const CloseBtn = document.getElementById('CloseBtn');
//CloseBtn.addEventListener('click', () => {
//    const myAnimation2 = gsap.to(camera.position, {
//        duration: 1, 
//        z: 8,
//        x: 2,
//        y: 0,
//        ease: "power2.inOut"    
//    });
//    const myAnimation3 = gsap.to(Group2.rotation, {
//        duration: 1, 
//        z: 0,
//        x: 0,
//        y: 0,
//        ease: "power2.inOut"    
//    });
//    const myAnimationSphere2 = gsap.to(Group5.position, {
//        duration: 1, 
//        z: 0,
//        x: 0, 
//        y: 0,
//        ease: "power2.inOut"  
//    });
//  myAnimation2.play();
//  myAnimation3.play();
//  myAnimationSphere2.play();
// console.log(cursor)
//});

//gsap.to(Group3.rotation, { duration: 2, delay: 1, y: 3.07 })
//
//gsap.to(Group1.rotation, { duration: 3, delay: 1, y: 3 })


const objectsDistance = 4


const clock = new THREE.Clock()


let previousTime = 0


const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    previousTime = elapsedTime
    const deltaTime = elapsedTime - previousTime


    //console.log(deltaTime)
//        scene.rotation.y += 0.0005   
//        //Group1.rotation.y = scrollY /500
 //      Group1.rotation.y = scrollY / sizes.width * objectsDistance * 0.8 + (Math.PI * 0.51)
 Group1.rotation.y = (scrollY / sizes.height) * Math.PI * 1 + (Math.PI * 0.51);
       Group4.rotation.z = scrollY / sizes.width * objectsDistance * 1.8 + (Math.PI * 0.51)
       Group5.rotation.z = -scrollY / -sizes.width * objectsDistance * 1.8 + (Math.PI * 0.51)
//        const parallaxX = cursor.x * 0.05
//        const parallaxY = - cursor.y * 0.05
//            cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 0.05
//            cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 0.05 
//            //cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
//            //cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime
    // Render
    renderer.render(scene, camera)
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

